<template>
    <v-container grid-list-md pa-0 relative class="attendence_view">
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Result Blocked Students
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title">
                            <v-flex xs4 sm4>
                                <v-select outlined dense :loading="gradeLoading" :items="grades"
                                        class="pa-0" label="Grade" v-model="grade"
                                        :disabled="!grades.length"/>
                            </v-flex>
                            <v-flex xs4 sm4>
                                <v-select outlined dense :items="sections" class="pa-0" label="Section" v-model="section"
                                        :disabled="!grade"/>
                            </v-flex>
                        </v-card-title>
                    </v-card>

                    <div class="overlay attendance">

                        <div class="data-represent" v-if="form.items.data.length">
                            <div>
                                <span class="d-success"></span>&nbsp; <strong>{{totalStudents}}</strong>
                                <small> Total Students</small>
                            </div>
                            <div>
                                <span class="d-error"></span> &nbsp; <strong>
                                {{blockedStudents}}
                            </strong>
                                <small> Blocked Stuedents</small>
                            </div>
                        </div>
                        <v-data-table
                                :headers="headers"
                                :items="form.items.data"
                                hide-default-footer
                                :search="search"
                                :loading="form.loading"
                                :options.sync="pagination"
                                :server-items-length="form.items.meta.total"
                                style="margin-top: 15px">
                            <template  v-slot:item="{index, item}">
                                <tr>
                                    <td class="text-xs-left">{{ item.roll }}</td>
                                    <td class="text-xs-left">{{ item.name }}</td>

                                    <td class="text-xs-right" style="text-align: right">
                                        <v-checkbox :class="item.is_blocked?'blocked':'unblocked'"
                                                    @change="setRemarks(item)"
                                                    v-model="item.is_blocked"
                                                    color="red"
                                                    hide-details
                                        ></v-checkbox>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<style scoped>
    .inputLabelabsent {
        background: #e22712;
        color: white;
    }

    .inputRadio {
        display: none;
    }
</style>
<script>

    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    const NepaliDate = require('nepali-date');
    const dt = new NepaliDate();

    let oldState = [];
    export default {
        // components: {GradeSectionFilter},
        data: () => ({
            gradeLoading: false,
            form: new Form({
                id: '',
                is_blocked: '',
            }, '/api/result-block-list'),
            search: null,
            pagination: {
                rowsPerPage: 100
            },
            radio: 1,
            updateState: false,
            headers: [
                {text: 'Roll', align: 'left', value: 'roll', width: '10%'},
                {text: 'Name', align: 'left', value: 'name', width: 870},
                {text: 'Block Status', align: 'left', value: 'status', sortable: false}
            ],
            att_payload: [],
            section: '',
            grade: '',
            sections: [],
            grades: [],
            date: dt.format('YYYY-MM-DD'),
            id: '',
            picked: [],
            temp: {
                attendance_date: null,
                id: null,
                name: null,
                pivot: null,
                remarks: null,
                roll: null,
                status: null
            },
            remarksDialog: false,
            remarksTagLeave: [
                {text: 'Sick', labelclass: 'inputLabelLeave'},
                {text: 'Ceremony', labelclass: 'inputLabelLeave'},
                {text: 'Work', labelclass: 'inputLabelLeave'},
            ],
            remarksAbsent: [
                {text: 'No Information', labelclass: 'inputLabelabsent'},
                {text: 'Strike', labelclass: 'inputLabelabsent'},
                {text: 'Work', labelclass: 'inputLabelabsent'},
            ],
            remarksLate: [
                {text: 'Bus Delay', labelclass: 'inputLabelLate'},
                {text: 'Ceremony', labelclass: 'inputLabelLate'},
            ],
            remarksItems: [],
            btnDisabled: false,
        }),
        watch: {
            'form.dialog': function (value) {
                if (value === true) this.getBatches();
            },
            'grade': function () {
                this.getSections();
                this.att_payload = [];
            },
            'section': function () {
                this.get();
            },
        },
        computed: {
            // ...mapState(['batch', 'grade']),
            ...mapState(['batch']),
            blockedStudents() {
                let output = 0;
                if (this.form.items.data.length) {
                    this.form.items.data.map(res => {
                        if (res.is_blocked == 1) {
                            output++;
                        }
                    });
                }
                return output;
            },
            totalStudents() {
                let output = 0;
                if (this.form.items.data.length) {
                    output = this.form.items.data.length;
                }

                return output;
            },

        },

        mounted() {
            this.getGrades();
        },


        methods: {
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
					if(typeof json[key] == 'object') {
						let vals = [...json[key]];
                        if(vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
					}
					return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
				}).join('&') + '&sectionId=' + this.section;

            },
            get(params) {
                if (this.section) {
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    this.form.get(null, query).then(({data}) => {
                    })
                }
            },
            store() {
                this.form.section = this.section;
                this.form.date = this.date;
                this.form.attendence = this.att_payload;
                this.form.store().then(res => {
                    this.get();
                    if (res.data.code === 201) {
                        this.updateState = true;
                    }
                }).catch(err => {
                    if (err.data.message === 'Past date is not allowed. Please contact your admin.') {
                        this.btnDisabled = true;
                    }
                });
            },
            getGrades() {
                this.gradeLoading = true;
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false&filter=true&slim=true').then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {value: item.id, text: item.name, sections: item.sections}
                    });
                }).finally(() => {
                    this.gradeLoading = false;
                })
            },
            getSections() {
                this.section = '';
                this.sections = [];
                let $this = this;
                this.grades.map(function (grade) {
                    if (grade.value === $this.grade)
                        $this.sections = grade.sections.map(section => {
                            return {value: section.id, text: section.name, teacher: section.class_teacher}
                        });
                });
            },

            setRemarks(item) {
                this.form.id = item.enroll_id;
                if (item.is_blocked) this.form.is_blocked = 1;
                else this.form.is_blocked = 0;
                this.store()
            }
        }
    }
</script>
<style lang="scss" scoped>
    .v-chip, .v-chip .v-chip__content {
        border-radius: 3px;
        color: white;
        font-weight: bold;
    }

    .theme--light.v-icon {
        color: #2196F3;
    }

    /*.unblocked {*/
    /*.theme--light.v-icon {*/
    /*color: green !important;*/
    /*}*/
    /*}*/

    .attendance {
        .inner-overlay p {
            margin-top: 3%;
        }
    }
</style>